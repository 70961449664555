import React, { useRef, useEffect } from 'react';
import styled from 'styled-components';

function randomBetween(min, max) {
  return Math.random() * (max - min) + min;
}

const DrawingSurface = ({ eventParentRef, size }) => {
  const canvasRef = useRef();
  const squiggleColor = '#d8ffe2';
  const squiggleClickColor = '#d2f7ff';

  useEffect(() => {
    let nextMousePositionToDraw;
    canvasRef.current.getBoundingClientRect();
    const context = canvasRef.current.getContext('2d');
    const { width, height } = canvasRef.current.getBoundingClientRect();
    canvasRef.current.width = width;
    canvasRef.current.height = height;
    context.filter = `blur(${size / 2}px)`;

    const drawMousePosition = (x, y) => {
      const circle = new Path2D();
      circle.arc(x, y, size / 2, 0, 2 * Math.PI);

      context.fillStyle = squiggleColor;
      context.fill(circle);
    };

    const drawMouseMove = ({ clientX, clientY }) => {
      const { x, y } = canvasRef.current.getBoundingClientRect();
      drawMousePosition(clientX - x, clientY - y + window.scrollY);
    };

    const drawClick = ({ clientX, clientY }) => {
      const {
        x: mouseX,
        y: mouseY,
      } = canvasRef.current.getBoundingClientRect();
      const x = clientX - mouseX;
      const y = clientY - mouseY + window.scrollY;
      const clickSize = size * (Math.random() * (2.5 - 1.2) + 1.2);

      const circle = new Path2D();
      circle.arc(x, y, clickSize / 2, 0, 2 * Math.PI);

      const blurAmount = Math.max(randomBetween(20, size), 0);
      context.filter = `blur(${blurAmount}px)`;
      context.fillStyle = squiggleClickColor;
      context.fill(circle);
    };

    const drawWheelMove = ({ deltaX, deltaY }) => {
      if (nextMousePositionToDraw) {
        const currentMousePosition = nextMousePositionToDraw;
        drawMousePosition(
          currentMousePosition.x + deltaX,
          currentMousePosition.y + deltaY
        );
      }
    };

    eventParentRef.current.addEventListener('mousemove', drawMouseMove);
    eventParentRef.current.addEventListener('mousedown', drawClick);
    eventParentRef.current.addEventListener('wheel', drawWheelMove);
  }, []);

  return <StyledCanvas ref={canvasRef} />;
};

export default DrawingSurface;

const StyledCanvas = styled.canvas`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;
