import React from 'react';
import styled, { css } from 'styled-components';
import Grid from './Grid';

const LargeTitle = ({ children }) => (
  <Grid>
    <TitleText>{children}</TitleText>
  </Grid>
);

export default LargeTitle;

const TitleText = styled.div`
  font-size: 5.5vw;
  font-weight: 900;
  line-height: 1.1;
  margin-bottom: calc(var(--vertical-space) / 2);
  grid-column: left-text-start / right-text-end;

  ${props =>
    props.noBottomMargin &&
    css`
      margin-bottom: 0;
    `}

  @media screen and (max-width: 590px) {
    font-size: 32px;
    font-weight: 800;
  }

  @media screen and (min-width: 1440px) {
    font-size: 80px;
  }
`;
